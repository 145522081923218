import React from 'react'
import { Field } from 'formik'
import { FieldProps } from 'formik'
import FieldInput from 'common/components/FieldInput'
import FieldSwitch from 'common/components/FieldSwitch'
import { Row, Col } from 'antd'

import FieldSelect from 'common/components/FieldSelect'
import { countryStates, CountryListType } from 'common/utils/countryStates'

interface CompanyInformationFromProps {
  countryList: { name: string; code: string }[]
}
const CompanyInformationForm: React.FC<CompanyInformationFromProps> = (
  props
) => {
  const getCountryOptions = props.countryList.map((country) => ({
    key: country.name,
    value: country.code,
  }))

  const getStateOptions = (states: string[]) => {
    return states.map((state) => ({ key: state, value: state }))
  }

  const getSelectedCountry = (fieldProps: FieldProps) => {
    if (fieldProps.form.values.country === 'Canada') {
      return 'CA'
    } else {
      return fieldProps.form.values.country
    }
  }

  return (
    <>
      <Field
        label='Company name'
        name='organizationName'
        placeholder='Acme Inc.'
        component={FieldInput}
        required
      />
      {/* <Row style={{ gap: '1rem' }} > */}
      <Field
        label='Crypto Company'
        name='cryptoCompany'
        component={FieldSwitch}
      />
      {/* </Row> */}
      <Field
        label='Enable rewards page'
        name='enableRewardsPage'
        component={FieldSwitch}
      />
      <Field
        label='Reconcile partner'
        name='reconcilePartner'
        component={FieldSwitch}
      />
      <Field
        label='Address 1'
        name='addressOne'
        placeholder='123 Main St.'
        component={FieldInput}
        required
      />

      <Field
        label='Address 2'
        name='addressTwo'
        placeholder='Suite #, Building etc.'
        component={FieldInput}
      />
     
      <Field
        label='City'
        name='city'
        placeholder='Enter city name'
        component={FieldInput}
        required
      />

      <Field name='province'>
        {(fieldProps: FieldProps) => {
          const selectedCountry = getSelectedCountry(fieldProps)
          if (selectedCountry === 'CA' || selectedCountry === 'US') {
            const country = selectedCountry as CountryListType
            return (
              <FieldSelect
                {...fieldProps}
                options={getStateOptions(countryStates[country])}
                label='State/Province'
                placeholder='Please select'
                required
              />
            )
          } else {
            return (
              <FieldInput
                {...fieldProps}
                label='State/Province'
                placeholder='Enter province'
              />
            )
          }
        }}
      </Field>
  
      <Row justify='space-between'>
        <Col span={11}>
          <Field name='postalCode'>
            {(fieldProps: FieldProps) => {
              const selectedCountry = getSelectedCountry(fieldProps)
              return (
                <Field
                  label='Postal Code'
                  name='postalCode'
                  placeholder='Enter code'
                  component={FieldInput}
                  required={
                    selectedCountry === 'CA' || selectedCountry === 'US'
                  }
                  {...fieldProps}
                />
              )
            }}
          </Field>
        </Col>
        <Col span={11}>
          <Field name='country' as='select'>
            {(fieldProps: FieldProps) => {
              return (
                <FieldSelect
                  label='Country'
                  placeholder='Please select'
                  options={getCountryOptions}
                  onChange={() => {
                    fieldProps.form.setFieldValue('province', '')
                    fieldProps.form.setFieldValue('postalCode', '')
                  }}
                  required
                  {...fieldProps}
                />
              )
            }}
          </Field>
        </Col>
      </Row>
      <Field
        label='Website'
        name='website'
        placeholder='https://www.phaze.io'
        component={FieldInput}
        required
      />
    </>
  )
}
export default CompanyInformationForm
