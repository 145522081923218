import React, { useState, useEffect } from 'react'
import Moment from 'moment';
import { Button } from 'common/components/Button'
import {
  DashboardContainer,
  TodaySection,
  OverviewSection,
  SectionHeader,
  SectionTitle,
  SectionSubTitle,
  Filters
} from './styledComponents'
import useFetchDashboard from 'common/hooks/useFetchDashboard'
import { URL_DASHBOARD, URL_ME, URL_FETCH_BRANDS, URL_PARTNER_FETCH_BRANDS } from 'common/api/paths'
import { Role, RoleType } from 'common/store/auth/slice'
import useDownloadCSV from 'common/hooks/useDownloadCSV'
import {
  getSuperConfig,
  getPartnerConfig,
  getOverview
} from './dashboardConfig'
import Cards from './Cards'
import { DatePicker } from 'common/components/DatePicker'
import { Select } from 'common/components/Select'
import { CheckOutlined, DownloadOutlined } from '@ant-design/icons';

const { Option } = Select

interface DashboardProps {
  role: Role
  organizationName?: string | null
  reconcilePartner: null | boolean
}

const defaultFromDate = Moment().utc()
  .subtract(30, 'd').startOf('day')
  .format()
const defaultToDate = Moment().utc().endOf('day').format()

const Dashboard: React.FC<DashboardProps> = ({ role, organizationName, reconcilePartner }) => { 
  const dateFormat = 'YYYY-MM-DD'
  const url =
    (role === 'super_admin' || role === 'phaze_admin') ? URL_DASHBOARD : `${URL_ME}${URL_DASHBOARD}`

  const brandListUrl =  (role === 'super_admin' || role === 'phaze_admin') ? URL_FETCH_BRANDS : URL_PARTNER_FETCH_BRANDS
  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)
  const [isCustomRange, setIsCustomRange] = useState(false)
  const { data, fetchData, isLoading } = useFetchDashboard(url)
  const { downloadCSVcallback } = useDownloadCSV(`${brandListUrl}.csv`, (new Date()).toString(),'_list_of_brands.csv')

  useEffect(() => {
    if(role)
      fetchData(defaultFromDate, defaultToDate)
  }, [fetchData,role])

  const handleSelection = (value: unknown) => {
    if (value === 'useCustom') {
      setIsCustomRange(true)
    }

    if (value === 'useDefault') {
      setIsCustomRange(false)
      setFromDate(defaultFromDate)
      setToDate(defaultToDate)
    }
  }

  const totalSalesTitle = 'Lifetime Sales Summary';
  const accountDetails =
  //TO-DO: Update
  //@ts-ignore
  role === 'super_admin' || role === RoleType.PhazeAdmin ? getSuperConfig(data) : getPartnerConfig(data, reconcilePartner)
  //TO-DO: Update
  //@ts-ignore
  const salesOverview = getOverview(data, role)
  const displayExportBrands = (<Button
                                                type={'primary'}
                                                shape='round'
                                                style={{ margin: 5 }}
                                                onClick={() => downloadCSVcallback(null,null)}
                                              >
                                                Export All Brands <DownloadOutlined />
                                            </Button>)
                                            
  return (
    <DashboardContainer>
      <TodaySection>
        <SectionHeader>
            <span>
              <SectionTitle>
                {role === 'super_admin' || role === RoleType.PhazeAdmin
                  ? `Phaze`
                  : `${organizationName}`}
              </SectionTitle>
              <SectionSubTitle>
                {data && `(Last updated at ${data.updateTime} UTC)`}
              </SectionSubTitle>
            </span>
            <Filters>
            {displayExportBrands}
              <Select
                onChange={handleSelection}
                defaultValue={'useDefault'}
                style={{ width: 140 }}
              >
                <Option value={'useCustom'} data-testid='test-custom-range'>
                  Custom range
                </Option>
                <Option value={'useDefault'} data-testid='test-default-range'>
                  Last 30 days
                </Option>
              </Select>
              <DatePicker
                disabled={!isCustomRange}
                allowClear={false}
                defaultValue={Moment(defaultFromDate).utc()}
                value={Moment(fromDate).utc()}
                format={dateFormat}
                placeholder='select start date'
                onChange={(date: any) => date && setFromDate(date.utc().startOf('day').format())}
              />
              <DatePicker
                disabled={!isCustomRange}
                allowClear={false}
                defaultValue={Moment(defaultToDate)}
                value={Moment(toDate)}
                format={dateFormat}
                placeholder='select end date'
                onChange={(date: any) => date && setToDate(date.utc().add(1,'d').startOf('day').subtract(1,'millisecond').format())}
              />
              <Button
                type={'primary'}
                shape='round'
                style={{ margin: 0 }}
                onClick={() => fetchData(fromDate, toDate)}
              >
                Apply <CheckOutlined />
              </Button>
            </Filters>
        </SectionHeader>
        <Cards data={accountDetails} isLoading={isLoading} />
      </TodaySection>
      <OverviewSection>
        <SectionHeader>
          <SectionTitle>{totalSalesTitle}</SectionTitle>
        </SectionHeader>
        <Cards data={salesOverview} isLoading={isLoading} />
      </OverviewSection>
    </DashboardContainer>
  )
}

export default Dashboard
