import React, { useState } from 'react'

import { ContentContainer } from 'common/components/ContentContainer'
import PaginatedTable from 'common/components/PaginatedTable'
import { DatePicker } from 'common/components/DatePicker'
import useResourceList from 'common/hooks/useResourceList'
import { URL_HISTORY } from 'common/api/paths'
import { AdminAction } from 'common/store/schema/adminActions'

import useDownloadCSV from 'common/hooks/useDownloadCSV'

import { Input, Button } from 'antd'

import {
  HistoryContainer,
  SectionHeader,
  SectionTitle,
  Filters
} from './styledComponents'

import { DateTime } from 'luxon'
import Moment from 'moment'
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'

const History = () => {

  const fetchUrl = `/history/transactions`
  const dateFormat = 'yyyy-MM-DD';
  const defaultFromDate = DateTime.utc().minus({ days: 30 }).toString() // .toFormat('YYYY-MM-DD')
  const defaultToDate = DateTime.utc().toString() //.toFormat('YYYY-MM-DD')
  console.log('defaultFromDate', defaultFromDate)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [sortBy, setSortBy] = useState("created_at")
  const [order, setOrder] = useState("DESC")
  const [organisationName, setOrganisationName] = useState("")
  const [actionName, setActionName] = useState("")
  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)

  const { data, isLoading, total } = useResourceList<AdminAction>(
    URL_HISTORY,
    currentPage,
    pageSize, sortBy, order, 
    // organisatioName, actionName, fromDate, toDate

    organisationName === "" ? null : organisationName, 
    actionName === "" ? null : actionName, fromDate, toDate
  )
  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)
  const handlePageSizeChange = (perPage: number) => {
    setCurrentPage(1)
    setPageSize(perPage)
  }

  const onSortChange = (sortBy: string, order: string | null) => {
    setSortBy(sortBy)

    if(order)
      setOrder(order)
  }

  // const onSearched = (organisatioName: string | null) => {

  //   setOrganisationName(organisatioName ? organisatioName : "");
  //   setCurrentPage(1)
  // }

  const onFilterApplied = (filterField: string | null, filterValue: string | null) => {
    // setActionName(actionName ? actionName : "");
    // setCurrentPage(1)

    if(filterField === "organisationName")
      setOrganisationName(filterValue ? filterValue : "")
    else if(filterField === "actionName")
      setActionName(filterValue ? filterValue : "")
      
    setCurrentPage(1)
  }

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    // this.setState({
    //   searchText: selectedKeys[0],
    //   searchedColumn: dataIndex,
    // });
    // onSearched(selectedKeys[0])
    // setCurrentPage(1)
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    // this.setState({ searchText: '' });
    // onSearched(null)
    // setCurrentPage(1)
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:{setSelectedKeys: any, selectedKeys: any,confirm:any, clearFilters:any}) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys?selectedKeys[0]:""}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
      </div>
    ),
    filterIcon: (filtered:any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) => {

      // var filterField = Object.keys(value)[0];

      // if(filterField === "organisationName")
      //   onSearched(filterField)

      // record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      return true;
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        // setTimeout(() => searchInput.select());
      }
    },
    render: (text: any) =>
      (
        text
      ),
  });
  
  const columns = [
    {
      title: 'Date',
      key: 'created_at',
      dataIndex: 'created_at',
      width: 150,
      render: (timestamp: string) => {
        return Moment(timestamp).format('YYYY-MM-DD h:mm a')
      },
      sorter: (a: any, b: any) => { return 0}
    },
    {
      title: 'Organization Name',
      key: 'organisationName',
      dataIndex: 'organisationName',
      sorter: (a: any, b: any) => { return 0},
      width: 200,
      ...getColumnSearchProps('organisationName'),
    },
    {
      title: 'User',
      key: 'adminUserEmail',
      dataIndex: 'adminUserEmail',
      width: 160,
      sorter: (a: any, b: any) => { return 0}
    },
    {
      title: 'Action',
      key: 'actionName',
      dataIndex: 'actionName',
      width: 150,
      sorter: (a: any, b: any) => { return 0 },
      filters: [
        {
          text: 'login',
          value: 'login',
        },
        {
          text: 'Generate API Keys',
          value: 'generateApiKeys',
        },
        {
          text: 'Add Funds',
          value: 'addFunds'
        },{
          text: 'Create Account',
          value: 'createAccount'
        }
      ],
      onFilter: (value: any, record: any) => true,
      filterMultiple: false,
    },
    {
      title: 'Raw Data',
      key: 'data',
      dataIndex: 'data',
      width: 300,
      render: (response: object) => {
        const isEmpty = Object.keys(response).length === 0
        if (isEmpty) {
          return ''
        }
        return JSON.stringify(response)
      },
      sorter: (a: any, b: any) => { return 0}
    }
  ]

  const { downloadCSVcallback } = useDownloadCSV(`${fetchUrl}.csv`, "phaze")

  return (
    <HistoryContainer>
      <SectionHeader>
        <SectionTitle>
          History
        </SectionTitle>
        <Filters>
          <Button
            type='default'
            shape='round'
            onClick={() => downloadCSVcallback(fromDate, toDate)}
          >
            <DownloadOutlined />
            Download CSV
          </Button>
          <DatePicker
            allowClear={false}
            defaultValue={Moment(defaultFromDate).utc()}
            value={Moment(fromDate).utc()}
            format={dateFormat}
            placeholder='select start date'
            onChange={(date: any) => date && setFromDate(date.utc().format())}
          />
          <DatePicker
            allowClear={false}
            defaultValue={Moment(defaultToDate).utc()}
            value={Moment(toDate).utc()}
            format={dateFormat}
            placeholder='select end date'
            onChange={(date: any) => date && setToDate(date.utc().format())}
          />
        </Filters>
      </SectionHeader>
      <ContentContainer>
        <PaginatedTable
          total={total}
          loading={isLoading}
          data={data}
          //TO-DO: Update
          //@ts-ignore
          columns={columns}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          currentPage={currentPage}
          pageSize={pageSize}
          onSortChange={onSortChange}
          onFilterApplied={onFilterApplied}
          rowKey='id'
        />
      </ContentContainer>
      </HistoryContainer>
  )
}

export default History
