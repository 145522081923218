import React from 'react'

import { Button, Row, Switch, Modal } from 'antd'
import { Input } from 'common/components/Input'
import { StyledInput, Label } from './styledComponents'
import CopyToClipboard from 'common/components/CopyToClipBoard'
import { Mode } from 'common/store/accounts/actions'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'

const { confirm } = Modal

interface AccessKeyProps {
  id: number | null
  disabled : boolean
  organizationName: string
  apiKey?: string | null
  requestSecret?: string | null
  setApiKey: (id: number, mode: Mode) => void
  setSecret: (id: number, mode: Mode) => void
  setAccountDisable: (id: number, mode: Mode) => void
}
const AccessKeys: React.FC<AccessKeyProps> = ({
  id,
  disabled,
  organizationName,
  apiKey,
  requestSecret,
  setApiKey,
  setSecret,
  setAccountDisable
}) => {

  const showConfirmationRevoke = (id: number, name: string, setKey: (id: number, mode: Mode) => void) => {
    confirm({
      title: `Are you sure you want to revoke  ${name}?`,
      content: '',
      okText: 'Yes',
      onOk() {
        if(id){
          setKey(id, Mode.Revoke)
        }
      }
    })
  }

  const getInputAddon = (
    key: any,
    setKey: (id: number, mode: Mode) => void,
    name: string
  ) => {
    return key ? (
      <Button
        style={{ width: 175 }}
        danger
        onClick={id !== null ? () => showConfirmationRevoke(id,name, setKey) : undefined}
      >
        Revoke {name}
        <CloseOutlined />
      </Button>
    ) : (
      <Button
        style={{ width: 175 }}
        onClick={id !== null ? () => setKey(id, Mode.Generate) : undefined}
      >
        Generate {name}
        <PlusOutlined />
      </Button>
    )
  }

  //Confirm before enabling / disabling the account
  const showConfirmation = (id: number, disable: boolean) => {
    console.log(disable)

    let content = `Are you sure you would like to temporarily disable ${organizationName}? This would mean all requests from the given partner will be denied?`
    if(!disable)
      content = `Are you sure you would like to enable ${organizationName}? This would mean all requests from the given partner will be granted?`

    confirm({
      title: '',
      content: content,
      okText: 'Yes',
      onOk() {
        if(id){
          const mode = disable ? Mode.DisableAccount : Mode.EnableAccount
          setAccountDisable(id, mode)
        }
      }
    })
  }

  const onChange = (checked : boolean, id: number | null) =>{
    if(id)
      showConfirmation(id,checked)
  }

  const secretAddon = getInputAddon(requestSecret, setSecret, 'Secret')
  const apiAddon = getInputAddon(apiKey, setApiKey, 'API Key')

  return (
    <>
      <Row align='middle' style={{ marginBottom : '20px' }}>
        <Input.Group style={{ width: '30%', height : '20px' }}>
          <Label>Temporarily Disable Account Access  </Label>
        </Input.Group>
        <Switch checked={disabled}  onChange={(checked) => onChange(checked, id)}/>
      </Row>
      <Row align='middle'>
        <Input.Group style={{ width: '80%' }}>
          <Label>API Key</Label>
          <StyledInput
            disabled
            value={apiKey || ''}
            addonAfter={apiAddon}
            placeholder='API Key'
          />
        </Input.Group>
        {apiKey && (
          <CopyToClipboard
            text={apiKey}
            successMessage='Copied API Key to clipboard'
          />
        )}
      </Row>
      <Row align='middle'>
        <Input.Group style={{ width: '80%' }}>
          <Label>Secret</Label>
          <StyledInput
            disabled
            value={requestSecret || ''}
            placeholder='Secret'
            addonAfter={secretAddon}
          />
        </Input.Group>
        {requestSecret && (
          <CopyToClipboard
            text={requestSecret}
            successMessage='Copied Secret to clipboard'
          />
        )}
      </Row>
    </>
  )
}
export default AccessKeys
